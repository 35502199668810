@import "@skryv/core-ng1/theme/includes/index";
wrp-dossier-page {
  a {
    font-weight: $font-weight-light;
  }

  .control-comment {
    &.beslissing {
      margin-top: $base-spacing;
    }
  }
  .control-comment-content {
    white-space: break-spaces;

    border: $base-border;
    padding: $base-spacing;
    margin-top: calc($base-spacing/4);
  }

  skr-dossier-tile-active-task {
    .info-block {
      margin-top: -$base-spacing;
    }
  }

  .no-active-task {
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    margin-top: -$base-spacing;
    margin-bottom: $base-spacing;
    padding: 2 * $base-spacing $base-spacing;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .no-active-task-text {
      font-size: 1.5 * $base-font-size;
    }
  }

  .not-last-task {
    .task {
      border-bottom: 1px solid $secondary-menu-background;
    }
  }

  .process-steps-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
  }

  .process-steps-footer {
    margin: auto;
    cursor: pointer;
  }

  .process-steps-button {
    color: $white;
    text-align: center;
    font-size: 10px;
    line-height: 20px;

    background-color: $action-color;
    border-radius: 50%;

    height: 20px;
    width: 20px;
  }

  skr-dossier-tile-process-steps {
    .process-steps {
      margin-top: 0;
      padding-right: $base-spacing;
    }
  }

  .milestone-more-info {
    margin-top: $base-spacing;
  }

  wrp-factuurcontrole-summary-extended {
    .extended-summary-circles {
      padding: 0;
    }
  }
}
